import { DefaultTheme } from 'styled-components'

const theme: DefaultTheme = {
  colors: {
    common: {
      accentDark: '#D40A8B',
      accentLight: '#DEEAFC',
      alert: '#BF002F',
      background: '#F2F4F6',
      black: '#222222',
      black05: 'rgba(34, 34, 34, 0.05)',
      black10: 'rgba(34, 34, 34, 0.10)',
      black20: 'rgba(34, 34, 34, 0.20)',
      black62: 'rgba(34, 34, 34, 0.62)',
      black75: 'rgba(34, 34, 34, 0.75)',
      black95: 'rgba(34, 34, 34, 0.95)',
      border: 'rgba(34, 34, 34, 0.10)',
      closeButtonBackground: '#f0f0f5',
      darkBackground: '#5a5a5a',
      darkSeaGreen: '#05AB5F',
      darkSteelBlue: '#5775A8',
      endEvent: '#ffd5cc',
      error: '#f5d6e0',
      generalEvent: '#b7eee0',
      mediumBackground: '#DBE0E6',
      mediumSeaGreen: '#09B968',
      periwinkleExtraLight: '#F1F6FE',
      selected: '#666a71',
      white: '#ffffff',
    },
    destructive: {
      main: '#BF002F',
      text: '#FFFFFF',
    },
    primary: {
      main: '#D40A8B',
      text: '#FFFFFF',
    },
    secondary: {
      main: '#FFFFFF',
      text: '#5a5a5a',
    },
    tertiary: {
      main: '#7F7E82',
      text: '#FFFFFF',
    },
  },
  spacing: 8,
  zIndex: {
    dropdown: 20,
    modal: 100,
    sidebar: 10,
  },
}

export default theme
