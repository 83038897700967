import {
  ColumnDef,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { HeaderBar } from '@/components/admin/ScreenContainer.styled.ts'
import { TableContainer } from '@/components/admin/Table.tsx'
import { getTagsData, Tags } from '@/components/admin/Tags.tsx'
import { TitleSmall } from '@/components/common/Text.tsx'
import {
  useDeleteQueue,
  useQueues,
  useUpdateQueue,
} from '@/hooks/admin/useQueues.ts'
import { useUserGroups } from '@/hooks/admin/useUserGroups.ts'
import i18n from '@/i18n'
import { ActionButtons } from '@/screens/admin/UserManagement/ActionButtons.tsx'
import { ContentTable } from '@/screens/admin/UserManagement/ContentTable.tsx'
import { DeleteModal } from '@/screens/admin/UserManagement/DeleteModal.tsx'
import { EditModal } from '@/screens/admin/UserManagement/EditModal.tsx'
import { useQueueFormFields } from '@/screens/admin/UserManagement/Queues/useQueueFormFields.ts'
import { Queue } from '@/types/queue.ts'
import { UserGroup } from '@/types/userGroup.ts'

export const QueuesTable = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'userManagement.queues',
  })
  const { queues } = useQueues()
  const { queueFormFields } = useQueueFormFields()
  const { userGroups } = useUserGroups()
  const [queueToDelete, setQueueToDelete] = useState<string | null>(null)
  const [queueToEdit, setQueueToEdit] = useState<Queue | null>(null)

  const { isError: isErrorUpdating, updateQueue } = useUpdateQueue({
    onSuccess: () => {
      setQueueToEdit(null)
    },
  })
  const { deleteQueue, isError: isErrorDeleting } = useDeleteQueue({
    onSuccess: () => {
      setQueueToDelete(null)
    },
  })

  const userGroupsWithColor = getTagsData(userGroups)

  const columns = useMemo<ColumnDef<Queue>[]>(
    () => [
      {
        accessorKey: 'title',
        enableColumnFilter: false,
        header: t('columns.title'),
      },
      {
        accessorKey: 'description',
        enableColumnFilter: false,
        header: t('columns.description'),
      },
      {
        accessorKey: 'userGroups',
        cell: (info) => (
          <Tags
            colorMap={userGroupsWithColor}
            data={info.getValue() as UserGroup[]}
          />
        ),
        enableColumnFilter: false,
        header: t('columns.userGroups'),
      },
      {
        accessorFn: (row) => row,
        cell: ({ getValue }) => {
          const queue = getValue() as Queue
          return (
            <ActionButtons
              onDelete={() => setQueueToDelete(queue.id)}
              onEdit={() => setQueueToEdit(queue)}
            />
          )
        },
        enableColumnFilter: false,
        header: t('columns.actions'),
        id: 'actions',
      },
    ],
    [i18n.language],
  )

  const table = useReactTable<Queue>({
    columns,
    data: queues || [],
    getCoreRowModel: getCoreRowModel(),
  })

  return (
    <TableContainer>
      <HeaderBar>
        <TitleSmall>{t('title')}</TitleSmall>
      </HeaderBar>
      <ContentTable table={table} />
      {queueToEdit && (
        <EditModal
          closeModal={() => setQueueToEdit(null)}
          data={queueToEdit}
          errorMessage={t('editModal.error')}
          formFields={queueFormFields}
          isError={isErrorUpdating}
          onSave={(data) => {
            updateQueue({
              queue: data,
              queueId: queueToEdit.id,
            })
          }}
          selectOptions={{
            userGroups,
          }}
          title={t('editModal.title')}
        />
      )}
      {queueToDelete && (
        <DeleteModal
          caption={t('deleteModal.caption', {
            title: queues?.find((q) => q.id === queueToDelete)?.title,
          })}
          closeModal={() => {
            setQueueToDelete(null)
          }}
          errorMessage={t('deleteModal.error')}
          isError={isErrorDeleting}
          onDelete={() => deleteQueue({ queueId: queueToDelete })}
          title={t('deleteModal.title')}
        />
      )}
    </TableContainer>
  )
}
