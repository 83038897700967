import { StyledActionButton } from '@/components/common/Buttons.tsx'
import { downloadCSV } from '@/screens/admin/UserManagement/utils.ts'

type DownloadCSVButtonProps = {
  data: any[]
  disabled?: boolean
  fileName: string
  label: string
}

export const DownloadCSVButton = ({
  data,
  disabled = false,
  fileName,
  label,
}: DownloadCSVButtonProps) => {
  return (
    <StyledActionButton
      disabled={disabled}
      onClick={() => downloadCSV(data, fileName)}
      variant="Secondary"
    >
      {label}
    </StyledActionButton>
  )
}
