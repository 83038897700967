import { queryOptions, useQuery } from '@tanstack/react-query'

import { useFetchFromAPI } from '@/hooks/data/useFetchFromAPI.ts'
import { FeatureFlagFromAPI } from '@/types/featureFlag.ts'

export enum FeatureFlagKeys {
  ENABLE_CHAT_ASSISTANT = 'ENABLE_CHAT_ASSISTANT',
}

const featureFlagQueryOptions = queryOptions({
  queryKey: ['featureFlag'],
})

export const useFeatureFlags = (): {
  featureFlags: FeatureFlagFromAPI[]
  isLoading: boolean
  getFeatureFlagByKey: (flagKey: FeatureFlagKeys) => boolean | string[]
} => {
  const fetchAPI = useFetchFromAPI()

  const { data: featureFlags, isLoading } = useQuery<Array<FeatureFlagFromAPI>>(
    {
      queryFn: async () => fetchAPI(`feature-flags`),
      queryKey: featureFlagQueryOptions.queryKey,
    },
  )

  const getFeatureFlagByKey = (flagKey: string) => {
    const featureFlag = featureFlags?.find((flag) => flag.key === flagKey)
    if (!featureFlag) return false
    return featureFlag.enabled ? (featureFlag.value ?? true) : false
  }

  return {
    featureFlags: featureFlags ?? [],
    getFeatureFlagByKey,
    isLoading,
  }
}
