import { TabGroup, TabPanel, TabPanels } from '@headlessui/react'
import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'

import * as S from '@/components/admin/ScreenContainer.styled.ts'
import { StyledTab, StyledTabList } from '@/components/common/Tabs.tsx'
import { FeatureFlags } from '@/screens/admin/UserManagement/FeatureFlags/FeatureFlags.tsx'
import { QueuesDashboard } from '@/screens/admin/UserManagement/Queues/QueuesDashboard.tsx'
import { Roles } from '@/screens/admin/UserManagement/Roles/Roles.tsx'
import { Users } from '@/screens/admin/UserManagement/Users/Users.tsx'

export const UserManagement = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'userManagement',
  })

  return (
    <S.Container>
      <TabGroup as={Fragment}>
        <StyledTabList>
          <StyledTab>{t('tabs.users')}</StyledTab>
          <StyledTab>{t('tabs.roles')}</StyledTab>
          <StyledTab>{t('tabs.reasons')}</StyledTab>
          <StyledTab>{t('tabs.featureFlags')}</StyledTab>
        </StyledTabList>
        <TabPanels>
          <TabPanel>
            <Users />
          </TabPanel>
          <TabPanel>
            <Roles />
          </TabPanel>
          <TabPanel>
            <QueuesDashboard />
          </TabPanel>
          <TabPanel>
            <FeatureFlags />
          </TabPanel>
        </TabPanels>
      </TabGroup>
    </S.Container>
  )
}
