import { FilterFn } from '@tanstack/react-table'
import dayjs from 'dayjs'

export const fuzzyFilter: FilterFn<any> = (row, columnId, value) => {
  // Return if the item should be filtered in/out
  if (typeof row.getValue(columnId) === 'string') {
    return (row.getValue(columnId) as string)
      .toLowerCase()
      .includes(value.toLowerCase())
  }
  if (Array.isArray(value) && value.length === 0) {
    return true
  }
  if (Array.isArray(row.getValue(columnId))) {
    return (row.getValue(columnId) as Array<string>).some((r) =>
      value.includes(r),
    )
  }
  return false
}

export const formatNumber = (number: number) => {
  const numberStr = String(number)
  const reversedStr = numberStr.split('').reverse().join('')
  const formattedParts = []
  for (let i = 0; i < reversedStr.length; i += 3) {
    formattedParts.push(reversedStr.substring(i, i + 3))
  }

  return formattedParts.join(' ').split('').reverse().join('')
}

const convertToCSV = (dataArray: any[], separator: string = ';') => {
  let str = ''

  // attach headers
  Object.keys(dataArray[0]).forEach((key, index) => {
    if (index !== 0) {
      str += separator
    }
    str += key
  })
  str += '\r\n'

  for (let i = 0; i < dataArray.length; i++) {
    let line = ''
    for (const index in dataArray[i]) {
      if (line !== '') line += separator
      let value = dataArray[i][index]
      if (typeof value === 'string') {
        value = '"' + dataArray[i][index] + '"'
      }
      line += value ?? ''
    }
    str += line + '\r\n'
  }
  return str
}

export const downloadCSV = (
  data: Record<string, string>[],
  fileName: string,
  separator?: string,
) => {
  const csvData = new Blob([convertToCSV(data, separator)], {
    type: 'text/csv',
  })
  const csvURL = URL.createObjectURL(csvData)
  const link = document.createElement('a')
  link.href = csvURL
  link.download = `${fileName}_${dayjs().format('DD_MM_YYYY')}.csv`
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}
