import {
  ChatLoader,
  ChatSuggestion,
} from '@/components/templates/ChatArea/ChatComponent'
import { useSocket } from '@/contexts/useSocketContext.ts'
import {
  useActiveChatSuggestion,
  useSendFeedback,
} from '@/hooks/useActiveChatSuggestion.ts'

export const SuggestedChatMessage = () => {
  const { isLoading, message } = useActiveChatSuggestion()
  const { addMessageToActiveConversation } = useSocket()
  const sendFeedback = useSendFeedback()

  return (
    <>
      {isLoading && <ChatLoader />}
      {message && (
        <ChatSuggestion
          id={message.traceId}
          content={message.content}
          onSend={(content: string) => {
            addMessageToActiveConversation(content, [])
          }}
          onFeedback={(feedback) => {
            sendFeedback(message.traceId, feedback)
          }}
        />
      )}
    </>
  )
}
