import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { DownloadCSVButton } from '@/components/admin/DownloadCSVButton.tsx'
import * as S from '@/components/admin/ScreenContainer.styled.ts'
import { StyledActionButton } from '@/components/common/Buttons.tsx'
import { Spinner, SpinnerContainer } from '@/components/common/Spinner.tsx'
import { Header2 } from '@/components/common/Text.tsx'
import { useRoles } from '@/hooks/admin/useRoles.ts'
import { useCreateUser, useUsers } from '@/hooks/admin/useUsers.ts'
import { EditModal } from '@/screens/admin/UserManagement/EditModal.tsx'
import { tagColors } from '@/screens/admin/UserManagement/tagColors.ts'
import { UsersTable } from '@/screens/admin/UserManagement/Users/UsersTable.tsx'
import { useUserFormFields } from '@/screens/admin/UserManagement/Users/useUserFormFields.ts'
import { User } from '@/types/user.ts'

export const Users = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'userManagement',
  })
  const { isLoading: isLoadingUsers, users } = useUsers()
  const { userFormFields } = useUserFormFields()
  const { roles } = useRoles()
  const { createUser, isError: isErrorCreatingUser } = useCreateUser({
    onSuccess: () => {
      setNewUserModal(false)
    },
  })

  const [newUserModal, setNewUserModal] = useState<boolean>(false)

  const mapRoleWithColor = roles?.map((role, index) => {
    return {
      ...role,
      ...tagColors[index],
    }
  })

  return (
    <S.TabContentSection>
      <S.HeaderBar>
        <Header2>{t('tabs.users')}</Header2>
        <S.StyledActionButtons>
          <StyledActionButton
            onClick={() => setNewUserModal(true)}
            variant="Primary"
          >
            {t('users.actions.add')}
          </StyledActionButton>
          <DownloadCSVButton
            data={users
              .filter((user) => user.customClaims?.patientId)
              .map((user) => ({
                creationDate: user.metadata.creationTime,
                lastRefresh: user.metadata.lastRefreshTime,
                patientId: user.customClaims.patientId,
              }))}
            disabled={isLoadingUsers || users.length === 0}
            fileName="users"
            label={t('users.actions.download')}
          />
        </S.StyledActionButtons>
      </S.HeaderBar>
      {isLoadingUsers ? (
        <SpinnerContainer>
          <Spinner />
        </SpinnerContainer>
      ) : (
        <>
          <UsersTable roles={mapRoleWithColor} users={users} />
          {newUserModal && (
            <EditModal
              closeModal={() => setNewUserModal(false)}
              errorMessage={t('users.createModal.error')}
              formFields={userFormFields}
              isError={isErrorCreatingUser}
              onSave={(user: User) =>
                createUser({
                  user,
                })
              }
              title={t('users.createModal.title')}
            />
          )}
        </>
      )}
    </S.TabContentSection>
  )
}
