import styled from 'styled-components'

import { StyledDestructiveButton } from '@/components/common/Buttons.tsx'
import { CloseButton } from '@/components/common/CloseButton.tsx'
import { StyledModal } from '@/components/common/Modal.tsx'
import { NAVBAR_HEIGHT } from '@/components/templates/NavigationBar/NavigationBar.styled.ts'

export const Main = styled.main`
  display: flex;
  flex-direction: column;
  height: calc(100vh - ${NAVBAR_HEIGHT}px);
  overflow: auto;
`

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 16px;
  padding-bottom: 24px;
`

export const SectionHeader = styled(Header)`
  justify-content: space-between;
`

export const Section = styled.section`
  padding: 32px 0;
  border-top: 1px solid ${({ theme }) => theme.colors.common.border};
`

export const TilesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 21px;
`

export const Content = styled.div`
  padding: 40px;
  border-right: 1px solid ${(props) => props.theme.colors.common.border};
`

export const Actions = styled.footer`
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 20px 40px;
  border-top: 1px solid ${({ theme }) => theme.colors.common.black10};
  margin-top: auto;
  position: sticky;
  bottom: 0;
  background-color: ${({ theme }) => theme.colors.common.white};
  border-right: 1px solid ${(props) => props.theme.colors.common.border};
`
export const CancelButton = styled(StyledDestructiveButton)`
  padding: 8px 24px;
`

export const Modal = styled(StyledModal)`
  width: 60%;
  max-width: 600px;
  max-height: 100%;
  padding: 12px;
`

export const CloseModalButton = styled(CloseButton)`
  position: absolute;
  top: 2px;
  right: 2px;
`

export const PreviewVideo = styled.video`
  width: 100%;
  max-width: 600px;
`
