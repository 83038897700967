import lodashUnescape from 'lodash/unescape'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import LightbulbSvg from '@/assets/svgs/lightbulb.svg'
import { StyledActionButton } from '@/components/common/Buttons.tsx'
import { ModalBackground } from '@/components/common/Modal.tsx'
import { Header3, Header4 } from '@/components/common/Text.tsx'
import { Feedback } from '@/types/conversation.ts'
import { DEFECTS } from '~common/assistant/types.ts'

import {
  MessageEventContainer,
  StyledActionButtons,
} from './ChatComponent.styled.ts'
import * as S from './ChatSuggestion.styled.ts'

type ChatSuggestionInputProps = {
  initialContent: string
  onSave: (content: string) => void
  onCancel: () => void
  rows: number
}

const ChatSuggestionInput = ({
  initialContent,
  onCancel,
  onSave,
  rows,
}: ChatSuggestionInputProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'chat',
  })
  const [editedContent, setEditedContent] = useState(initialContent)

  return (
    <>
      <S.StyledTextArea
        value={editedContent}
        onChange={(e) => setEditedContent(e.target.value)}
        placeholder={t('inputPlaceholder')}
        rows={rows}
      />
      <StyledActionButtons>
        <StyledActionButton
          variant="Secondary"
          onClick={() => {
            onCancel()
            setEditedContent(initialContent)
          }}
        >
          {t('actions.cancel')}
        </StyledActionButton>
        <StyledActionButton
          variant="Primary"
          onClick={() => {
            onSave(editedContent)
          }}
          disabled={!editedContent}
        >
          {t('actions.save')}
        </StyledActionButton>
      </StyledActionButtons>
    </>
  )
}

type FeedbackModalProps = {
  onClose: () => void
  content: string
  newContent: string
  onSend: (feedback: Feedback) => void
}

const FeedbackModal = ({
  content,
  newContent,
  onClose,
  onSend,
}: FeedbackModalProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'chat.suggestion.feedback',
  })

  const [selectedDefects, setSelectedDefects] = useState<string[]>([])

  const toggleSelection = (id: string) => {
    setSelectedDefects((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((selectedId) => selectedId !== id)
        : [...prevSelected, id],
    )
  }
  return (
    <ModalBackground closeModal={onClose}>
      <S.Modal>
        <Header3>{t('header')}</Header3>
        <S.ModalContent>
          <div>
            <S.ModalCaption>{t('originalMessage')}</S.ModalCaption>
            <S.Message>{content}</S.Message>
          </div>
          <div>
            <S.ModalCaption>{t('editedMessage')}</S.ModalCaption>
            <S.EditedMessage>{newContent}</S.EditedMessage>
          </div>
          <S.Divider />
          <Header4>{t('defects')}</Header4>
          <S.TagList>
            {DEFECTS.map((defect) => (
              <S.Tag
                key={defect}
                checked={selectedDefects.includes(defect)}
                onChange={() => toggleSelection(defect)}
              >
                {defect}
              </S.Tag>
            ))}
          </S.TagList>

          <S.ModalButton
            variant="Primary"
            disabled={selectedDefects.length === 0}
            onClick={() => {
              onSend({
                correction: newContent,
                defects: selectedDefects,
                rating: 'bad',
              })
            }}
          >
            {t('send')}
          </S.ModalButton>
        </S.ModalContent>
      </S.Modal>
    </ModalBackground>
  )
}

type ChatSuggestionProps = {
  id: string
  content: string
  onSend: (content: string) => void
  onFeedback: (feedback: Feedback) => void
}

export function ChatSuggestion({
  content,
  onFeedback,
  onSend,
}: ChatSuggestionProps) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'chat',
  })
  const messageContainer = useRef<HTMLDivElement>(null)

  const [editing, setEditing] = useState(false)
  const [newContent, setNewContent] = useState(content)
  const [feedbackModal, setFeedbackModal] = useState(false)
  const [rows, setRows] = useState(1)

  useEffect(() => {
    setEditing(false)
    setNewContent(content)
    if (messageContainer.current) {
      setRows(Math.round(messageContainer.current.scrollHeight / 22) - 1)
    }
  }, [content, messageContainer])

  return (
    <MessageEventContainer align="right" isFirst>
      <S.MessageLabel>
        <S.MessageLabelImage src={LightbulbSvg} alt="lightbulb icon" />
        <S.MessageLabelText>{t('suggestion.messageLabel')}</S.MessageLabelText>
      </S.MessageLabel>
      {!editing && (
        <>
          {newContent && (
            <S.SMessageContainer ref={messageContainer}>
              {lodashUnescape(newContent)}
            </S.SMessageContainer>
          )}
          <StyledActionButtons>
            {newContent !== content && (
              <StyledActionButton
                variant="Secondary"
                onClick={() => {
                  setNewContent(content)
                }}
              >
                {t('actions.revert')}
              </StyledActionButton>
            )}
            <StyledActionButton
              variant="Secondary"
              onClick={() => setEditing(true)}
            >
              {t('actions.edit')}
            </StyledActionButton>
            <StyledActionButton
              variant="Primary"
              onClick={() => {
                if (newContent !== content) {
                  setFeedbackModal(true)
                } else {
                  onSend(content)
                }
              }}
            >
              {t('actions.approveAndSend')}
            </StyledActionButton>
          </StyledActionButtons>
        </>
      )}
      {editing && (
        <ChatSuggestionInput
          initialContent={newContent}
          onSave={(content) => {
            setNewContent(content)
            setEditing(false)
          }}
          onCancel={() => setEditing(false)}
          rows={rows}
        />
      )}

      {feedbackModal && (
        <FeedbackModal
          onClose={() => setFeedbackModal(false)}
          content={content}
          newContent={newContent}
          onSend={(feedback) => {
            setFeedbackModal(false)
            onFeedback(feedback)
            onSend(newContent)
          }}
        />
      )}
    </MessageEventContainer>
  )
}
