import {
  ColumnDef,
  ColumnFiltersState,
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  PaginationState,
  useReactTable,
} from '@tanstack/react-table'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { RxCheckCircled, RxChevronLeft, RxChevronRight } from 'react-icons/rx'

import { FlexContainer } from '@/components/admin/ScreenContainer.styled.ts'
import { CenteredCell, TableCaption } from '@/components/admin/Table.tsx'
import { Tag, Tags } from '@/components/admin/Tags.styled.ts'
import { useDeleteUser, useUpdateUser } from '@/hooks/admin/useUsers.ts'
import i18n from '@/i18n'
import { ActionButtons } from '@/screens/admin/UserManagement/ActionButtons.tsx'
import { ContentTable } from '@/screens/admin/UserManagement/ContentTable.tsx'
import { DeleteModal } from '@/screens/admin/UserManagement/DeleteModal.tsx'
import { EditModal } from '@/screens/admin/UserManagement/EditModal.tsx'
import { useUserFormFields } from '@/screens/admin/UserManagement/Users/useUserFormFields.ts'
import {
  formatNumber,
  fuzzyFilter,
} from '@/screens/admin/UserManagement/utils.ts'
import { RoleWithColor } from '@/types/role.ts'
import { User } from '@/types/user.ts'

declare module '@tanstack/table-core' {
  // @ts-expect-error required describe for column meta
  interface ColumnMeta {
    isBooleanFilter?: boolean
    isSelectFilter?: boolean
  }
}

type UsersTableProps = {
  roles?: RoleWithColor[]
  users: User[]
}
export const UsersTable = ({ roles, users }: UsersTableProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'userManagement.users',
  })
  const { userEditFormFields } = useUserFormFields()
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])
  const [userIdToDelete, setUserIdToDelete] = useState<string | null>()
  const [userToEdit, setUserToEdit] = useState<User | null>(null)
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 20,
  })

  const { isError, updateUser } = useUpdateUser({
    onSuccess: () => {
      handleStopEditingUser()
    },
  })

  const { deleteUser } = useDeleteUser({
    onSuccess: () => {
      setUserIdToDelete(null)
    },
  })

  const handleSetDeleteUser = useCallback((userId: string) => {
    setUserIdToDelete(userId)
  }, [])

  const handleStartEditingUser = useCallback((user: User) => {
    setUserToEdit(user)
  }, [])

  const handleStopEditingUser = useCallback(() => {
    setUserToEdit(null)
  }, [])

  const columns = useMemo<ColumnDef<User>[]>(
    () => [
      {
        accessorKey: 'emailVerified',
        cell: (info) => (
          <CenteredCell>
            {info.getValue() ? <RxCheckCircled color="#004785" /> : <></>}
          </CenteredCell>
        ),
        filterFn: (row, columnId, filterValue) =>
          !!filterValue && row.getValue(columnId),
        header: t('columns.verified'),
        meta: {
          isBooleanFilter: true,
        },
      },
      {
        accessorKey: 'displayName',
        header: t('columns.name'),
      },
      {
        accessorKey: 'email',
        header: t('columns.email'),
      },
      {
        accessorFn: (row) => row.customClaims?.roles ?? [],
        cell: (info) => (
          <Tags>
            {(info.getValue() as Array<string>)?.map((role) => {
              const roleWithColor = roles?.find((r) => r.value === role)
              return (
                <Tag
                  key={role}
                  backgroundColor={roleWithColor?.backgroundColor}
                  color={roleWithColor?.color}
                >
                  {roleWithColor ? roleWithColor.name : role}
                </Tag>
              )
            })}
          </Tags>
        ),
        filterFn: fuzzyFilter,
        header: t('columns.roles'),
        maxSize: 150,
        meta: {
          isSelectFilter: true,
        },
      },
      {
        accessorFn: (row) => row.customClaims?.patientId ?? '',
        filterFn: fuzzyFilter,
        header: t('columns.patientId'),
        id: 'patientId',
      },
      {
        accessorFn: (row) => row.metadata.lastSignInTime ?? '',
        enableColumnFilter: false,
        header: t('columns.lastSignIn'),
        id: 'lastSignIn',
      },
      {
        accessorFn: (row) => row,
        cell: ({ getValue }) => {
          const user = getValue() as User

          return (
            <ActionButtons
              onDelete={(e) => {
                handleSetDeleteUser(user.uid)
                e.stopPropagation()
              }}
              onEdit={(e) => {
                handleStartEditingUser(user)
                e.stopPropagation()
              }}
            />
          )
        },
        enableColumnFilter: false,
        header: t('columns.actions'),
        id: 'actions',
      },
    ],
    [roles, i18n.language],
  )

  const table = useReactTable<User>({
    columns,
    data: users,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    getCoreRowModel: getCoreRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onColumnFiltersChange: setColumnFilters,
    onPaginationChange: setPagination,
    state: {
      columnFilters,
      pagination,
    },
  })

  return (
    <>
      <TableCaption>
        {t('display', {
          amount: formatNumber(table.getPrePaginationRowModel().rows.length),
          total: formatNumber(users.length),
        })}
      </TableCaption>
      <ContentTable table={table} modalOnRowClick />
      <FlexContainer>
        <button
          disabled={!table.getCanPreviousPage()}
          onClick={() => table.previousPage()}
        >
          <RxChevronLeft />
        </button>
        <span>
          <strong>
            {table.getState().pagination.pageIndex + 1} of{' '}
            {table.getPageCount().toLocaleString()}
          </strong>
        </span>
        <button
          disabled={!table.getCanNextPage()}
          onClick={() => table.nextPage()}
        >
          <RxChevronRight />
        </button>
      </FlexContainer>
      {userToEdit && (
        <EditModal
          closeModal={handleStopEditingUser}
          data={userToEdit}
          errorMessage={t('editModal.error')}
          formFields={userEditFormFields}
          isError={isError}
          onSave={(user) =>
            updateUser({
              uid: user.uid,
              user: {
                ...user,
                customClaims: {
                  ...user.customClaims,
                  roles: user.customClaims?.roles?.map((role) =>
                    // @ts-expect-error roles coming from EditModal are Role array and not string array
                    typeof role === 'string' ? role : role.value,
                  ),
                },
              },
            })
          }
          selectOptions={{
            'customClaims.roles': roles,
          }}
          title={t('editModal.title')}
        />
      )}
      {userIdToDelete && (
        <DeleteModal
          caption={t('deleteModal.caption', {
            email: users.find((user) => user.uid === userIdToDelete)?.email,
          })}
          closeModal={() => {
            setUserIdToDelete(null)
          }}
          onDelete={() => deleteUser({ uid: userIdToDelete })}
          title={t('deleteModal.title')}
        />
      )}
    </>
  )
}
