import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'

import { DashboardCard } from '@/components/admin/DashboardCard.tsx'
import { useMetrics } from '@/hooks/admin/useMetrics.ts'
import {
  COLORS,
  CONTAINER_HEIGHT,
  SMALL_CONTAINER_WIDTH,
} from '@/screens/admin/Dashboard/Charts/constants.ts'

const DATE_FORMAT = 'YYYY-MM-DD'

export const ChatResponseCharts = ({
  selectedYear,
}: {
  selectedYear: number
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'adminDashboard.chats',
  })

  const { metrics: { conversations } = {} } = useMetrics(
    dayjs().year(selectedYear).startOf('year').format(DATE_FORMAT),
    dayjs().year(selectedYear).endOf('year').format(DATE_FORMAT),
  )
  const data =
    conversations?.byMonth.map((month, index) => ({
      ...month,
      month: dayjs().month(index).format('MMM'),
    })) || []

  return (
    <>
      <DashboardCard
        caption={t('chart.responseTime.caption', {
          total: conversations?.total,
          year: selectedYear,
        })}
        title={t('chart.responseTime.title')}
      >
        <LineChart
          data={data}
          height={CONTAINER_HEIGHT}
          width={SMALL_CONTAINER_WIDTH}
        >
          <XAxis dataKey="month" />
          <YAxis
            label={{
              angle: -90,
              position: 'insideLeft',
              value: t('chart.responseTime.yAxis'),
            }}
          />
          <CartesianGrid stroke="#eee" strokeDasharray="3 3" />
          <Tooltip />
          <Legend />
          <Line
            dataKey="averageFirstReplyTime"
            name={t('chart.responseTime.line.firstReplyTime')}
            stroke={COLORS[0]}
            type="monotone"
            unit="h"
            connectNulls
          />
          <Line
            dataKey="averageOpenedTime"
            name={t('chart.responseTime.line.openedTime')}
            stroke={COLORS[1]}
            type="monotone"
            unit="h"
            connectNulls
          />
        </LineChart>
      </DashboardCard>
      <DashboardCard
        caption={t('chart.answeredWithin4Hours.caption', {
          total: conversations?.total,
          year: selectedYear,
        })}
        title={t('chart.answeredWithin4Hours.title')}
      >
        <LineChart
          data={data}
          height={CONTAINER_HEIGHT}
          width={SMALL_CONTAINER_WIDTH}
        >
          <XAxis dataKey="month" />
          <YAxis
            domain={[0, 100]}
            label={{ position: 'insideLeft', value: '%' }}
            type="number"
          />
          <CartesianGrid stroke="#eee" strokeDasharray="3 3" />
          <Tooltip />
          <Legend name="percent" />
          <Line
            dataKey="answeredWithin4HoursPercentage"
            name={t('chart.answeredWithin4Hours.line.answeredWithin4Hours')}
            stroke={COLORS[2]}
            type="monotone"
            unit="%"
            connectNulls
          />
        </LineChart>
      </DashboardCard>
    </>
  )
}
