import {
  Bar,
  BarChart as REBarChart,
  CartesianGrid,
  Legend,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'

import {
  BIG_CONTAINER_WIDTH,
  COLORS,
  CONTAINER_HEIGHT,
} from '@/screens/admin/Dashboard/Charts/constants.ts'

type BarChartProps = {
  bar1DataKey: string
  bar2DataKey?: string
  data: any[]
  xDataKey?: string
  yDataKey?: string
}
export const BarChart = ({
  bar1DataKey,
  bar2DataKey,
  data,
  xDataKey,
  yDataKey,
}: BarChartProps) => {
  return (
    <REBarChart
      data={data}
      height={CONTAINER_HEIGHT}
      width={BIG_CONTAINER_WIDTH}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey={xDataKey} />
      <YAxis dataKey={yDataKey} />
      <Tooltip />
      <Legend />
      <Bar dataKey={bar1DataKey} fill={COLORS[0]} />
      {bar2DataKey && <Bar dataKey={bar2DataKey} fill={COLORS[1]} />}
    </REBarChart>
  )
}
