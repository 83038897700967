import { Loader } from '@/components/common/Loader.tsx'

import * as S from './ChatComponent.styled'

export const ChatLoader = () => {
  return (
    <S.MessageEventContainer align="right" isFirst>
      <S.ClinicianMessageContainer>
        <Loader />
      </S.ClinicianMessageContainer>
    </S.MessageEventContainer>
  )
}
