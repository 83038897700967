import styled, { css, DefaultTheme, keyframes } from 'styled-components'

const circleWidth = `calc(100% / 3)`
const dotPulse = keyframes`
  33% {
    background-size: ${circleWidth} 0%,
    ${circleWidth} 100%,
    ${circleWidth} 100%;
  }
  50% {
    background-size: ${circleWidth} 100%,
    ${circleWidth} 0%,
    ${circleWidth} 100%;
  }
  66% {
    background-size: ${circleWidth} 100%,
    ${circleWidth} 100%,
    ${circleWidth} 0%;
  }
`

const circle = (theme: DefaultTheme) =>
  `no-repeat radial-gradient(circle closest-side, ${theme.colors.common.black75} 90%, transparent)`

export const Loader = styled.div`
  width: 40px;
  aspect-ratio: 4;
  background: ${({ theme }) => css`
    ${circle(theme)} 0% 50%,
    ${circle(theme)} 50% 50%,
    ${circle(theme)} 100% 50%;
  `}
  background-size: ${circleWidth} 100%;
  animation: ${dotPulse} 1s infinite linear;
`
