import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import * as S from '@/components/admin/ScreenContainer.styled.ts'
import { StyledActionButton } from '@/components/common/Buttons.tsx'
import { Header2 } from '@/components/common/Text.tsx'
import {
  useCreatePermission,
  usePermissions,
} from '@/hooks/admin/usePermissions.ts'
import { useCreateRole, useRoles } from '@/hooks/admin/useRoles.ts'
import { EditModal } from '@/screens/admin/UserManagement/EditModal.tsx'
import { PermissionsTable } from '@/screens/admin/UserManagement/Roles/permissions/PermissionsTable.tsx'
import { usePermissionFormFields } from '@/screens/admin/UserManagement/Roles/permissions/usePermissionFormFields.ts'
import { RolesTable } from '@/screens/admin/UserManagement/Roles/RolesTable.tsx'
import { useRoleFormFields } from '@/screens/admin/UserManagement/Roles/useRoleFormFields.ts'
import { tagColors } from '@/screens/admin/UserManagement/tagColors.ts'
import { Permission } from '@/types/permission.ts'

export const Roles = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'userManagement',
  })

  const { roles } = useRoles()
  const { roleFormFields } = useRoleFormFields()
  const [newRoleModal, setNewRoleModal] = useState<boolean>(false)
  const { createRole, isError: isErrorCreatingRole } = useCreateRole({
    onSuccess: () => {
      setNewRoleModal(false)
    },
  })

  const { permissions } = usePermissions()
  const { permissionFormFields } = usePermissionFormFields()
  const [newPermissionModal, setNewPermissionModal] = useState<boolean>(false)
  const { createPermission, isError: isErrorCreatingPermission } =
    useCreatePermission({
      onSuccess: () => {
        setNewPermissionModal(false)
      },
    })

  const mapPermissionWithColor = permissions?.map((permission, index) => {
    return {
      ...permission,
      id: permission.id,
      ...tagColors[index],
    }
  })

  return (
    <S.TabContentSection>
      <S.HeaderBar>
        <Header2>{t('tabs.roles')}</Header2>
        <S.StyledActionButtons>
          <StyledActionButton
            onClick={() => setNewRoleModal(true)}
            variant="Primary"
          >
            {t('roles.actions.add')}
          </StyledActionButton>
          <StyledActionButton
            onClick={() => setNewPermissionModal(true)}
            variant="Secondary"
          >
            {t('permissions.actions.add')}
          </StyledActionButton>
        </S.StyledActionButtons>
      </S.HeaderBar>
      <S.FlexContainer>
        <S.TableContainer>
          <RolesTable permissions={mapPermissionWithColor} roles={roles} />
        </S.TableContainer>
        <S.TableContainer>
          <PermissionsTable permissions={permissions} />
        </S.TableContainer>
      </S.FlexContainer>
      {newRoleModal && (
        <EditModal
          closeModal={() => setNewRoleModal(false)}
          errorMessage={t('roles.createModal.error')}
          formFields={roleFormFields}
          isError={isErrorCreatingRole}
          onSave={(role) =>
            createRole({
              role,
            })
          }
          selectOptions={{
            permissions,
          }}
          title={t('roles.createModal.title')}
        />
      )}
      {newPermissionModal && (
        <EditModal
          caption={t('permissions.createModal.caption')}
          closeModal={() => setNewPermissionModal(false)}
          errorMessage={t('permissions.createModal.error')}
          formFields={permissionFormFields}
          isError={isErrorCreatingPermission}
          onSave={(permission: Permission) =>
            createPermission({
              permission,
            })
          }
          title={t('permissions.createModal.title')}
        />
      )}
    </S.TabContentSection>
  )
}
