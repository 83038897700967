import React from 'react'
import styled from 'styled-components'

import { CloseButton } from '@/components/common/CloseButton.tsx'
import { BodyS } from '@/components/common/Text.tsx'

const TileContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 16px;
  border: 1px solid ${({ theme }) => theme.colors.common.border};
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.common.white};
`

const StyledCloseButton = styled(CloseButton)`
  position: absolute;
  top: 6px;
  right: 11px;
`

const ImageContainer = styled.div`
  margin-bottom: 16px;
  flex: 1;
  background-color: ${({ theme }) => theme.colors.common.black05};
  display: flex;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }
`

const Title = styled(BodyS)`
  height: 56px;
  padding-bottom: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${({ theme }) => theme.colors.common.black};
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`

const Fields = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding-top: 16px;
  border-top: 1px solid ${({ theme }) => theme.colors.common.border};
`

type TileProps = {
  children?: React.ReactNode
  imageSrc: string
  onClose?: React.MouseEventHandler<HTMLButtonElement>
  title: string
}

export const Tile = ({ children, imageSrc, onClose, title }: TileProps) => {
  return (
    <TileContainer>
      <StyledCloseButton onClick={onClose} />
      <ImageContainer>
        <img alt="splint-image" src={imageSrc} />
      </ImageContainer>
      <Title fontWeight="bold">{title}</Title>
      {children && <Fields>{children}</Fields>}
    </TileContainer>
  )
}
