import React from 'react'
import styled from 'styled-components'

import CloseIcon from '@/assets/svgs/close-line.svg'

const StyledCloseButton = styled.button`
  padding: 4px;
  display: flex;
  align-items: center;
  border-radius: 50%;
  width: 31px;
  height: 31px;
  background-color: ${({ theme }) => theme.colors.common.white};

  &:hover {
    background-color: ${({ theme }) => theme.colors.common.background};
  }
`

export const CloseButton = ({
  className,
  onClick,
}: {
  onClick?: React.MouseEventHandler<HTMLButtonElement>
  className?: string
}) => (
  <StyledCloseButton onClick={onClick} className={className}>
    <img alt="close button" src={CloseIcon} />
  </StyledCloseButton>
)
