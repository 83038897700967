import dayjs from 'dayjs'
import React, { useEffect, useRef, useState } from 'react'
import { MdDelete } from 'react-icons/md'
import { RxPlus } from 'react-icons/rx'

import Send from '@/assets/svgs/send.svg'
import { Header3, TinyTitle } from '@/components/common/Text.tsx'
import { TextArea } from '@/components/common/TextArea.tsx'
import { useDcAssistantChats } from '@/hooks/admin/useDcAssistantChats.ts'
import {
  DCAssistantChat as Chat,
  DCAssistantChatMessage as Message,
} from '~common/assistant/types.ts'

import * as S from './ChatDemo.styled.ts'

export const ChatDemo = () => {
  const chatContainerRef = useRef<HTMLDivElement>(null)

  const {
    activeChat,
    chats,
    createChat,
    deleteChat,
    error,
    sendMessage,
    setActiveChat,
  } = useDcAssistantChats()

  const [input, setInput] = useState<string>('')
  const [rows, setRows] = useState(1)
  const [messages, setMessages] = useState<Array<Message>>([])

  useEffect(() => {
    if (chatContainerRef) {
      chatContainerRef.current?.scrollIntoView()
    }
  }, [chatContainerRef, chats, activeChat, messages])

  useEffect(() => {
    setMessages(chats?.find((chat) => chat.id === activeChat)?.messages || [])
  }, [chats, activeChat])

  const handleSendMessage = () => {
    if (!input.trim()) return

    if (!activeChat) {
      createChat({
        content: input,
      })
      // save message to state to show it immediately, before the socket event
      setMessages([
        {
          content: input,
          role: 'user' as const,
        },
      ])
    } else {
      sendMessage({
        chatId: activeChat,
        content: input,
        role: 'user' as const,
      })
    }

    setInput('')
  }

  const handleOnChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    e.preventDefault()
    setInput(e.target.value)

    const maxRows = 10
    const textareaLineHeight = 20
    e.target.rows = 1 // reset rows so we can get the proper scroll height
    const currentRows = Math.floor(e.target.scrollHeight / textareaLineHeight)
    const newRows = Math.min(Math.max(currentRows, 1), maxRows)
    setRows(newRows)
    e.target.rows = newRows
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      handleSendMessage()
    }
  }

  const handleSelectActiveChat = (chat: Chat) => {
    setActiveChat(chat.id)
  }

  const handleCreateChat = () => {
    setActiveChat(undefined)
  }

  const handleDeleteChat = (chatId: string) => {
    deleteChat(chatId)
  }

  return (
    <S.Container>
      <S.ChatsSidebar>
        <S.SidebarHeader>
          <TinyTitle>Chats</TinyTitle>
          <S.NewChatButton onClick={handleCreateChat} disabled={!activeChat}>
            <RxPlus />
          </S.NewChatButton>
        </S.SidebarHeader>

        {chats?.map((chat) => (
          <S.ChatItem
            key={chat.id}
            as={chat.id === activeChat ? 'div' : 'button'}
            $isActive={chat.id === activeChat}
            onClick={() => handleSelectActiveChat(chat)}
          >
            <S.ChatInfo>
              <S.ChatTitle>
                {chat.messages?.[0]?.content || chat.id}
              </S.ChatTitle>
              <S.ChatDate>
                {dayjs(chat.createdAt).format('DD/MM/YYYY HH:mm')}
              </S.ChatDate>
            </S.ChatInfo>
            {chat.id === activeChat && (
              <S.DeleteButton onClick={() => handleDeleteChat(chat.id)}>
                <MdDelete width={16} height={16} />
              </S.DeleteButton>
            )}
          </S.ChatItem>
        ))}
      </S.ChatsSidebar>

      <S.Header>
        <Header3>Chat Assistant</Header3>
        {error && (
          <S.Error>Something went wrong! Try to refresh the screen</S.Error>
        )}
      </S.Header>

      <S.ChatContent>
        <S.MessagesContainer>
          {messages?.map((msg, index) => (
            <S.Message key={index} $isUser={msg.role === 'user'}>
              <S.MessageText $isUser={msg.role === 'user'}>
                {msg.content.replace(/\n/gi, '\n &nbsp;')}
              </S.MessageText>
              {msg.key && <S.MessageCaption>{msg.key}</S.MessageCaption>}
            </S.Message>
          ))}
          <div ref={chatContainerRef}></div>
        </S.MessagesContainer>

        <S.InputWrapper>
          <TextArea
            onChange={handleOnChange}
            onKeyDown={handleKeyDown}
            placeholder="Message AI assistant"
            rows={rows}
            value={input}
            withoutAttachment
          />
          <S.IconButton data-testid="sendMessage" onClick={handleSendMessage}>
            <img alt="send" src={Send} />
          </S.IconButton>
        </S.InputWrapper>
      </S.ChatContent>
    </S.Container>
  )
}
