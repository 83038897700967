import { Checkbox } from '@headlessui/react'
import styled from 'styled-components'

import { StyledActionButton } from '@/components/common/Buttons.tsx'
import { TextArea } from '@/components/common/Input.tsx'
import { StyledModal } from '@/components/common/Modal.tsx'
import { BodyM, BodyS } from '@/components/common/Text.tsx'
import { MessageContainer } from '@/components/templates/ChatArea/ChatComponent/ChatComponent.styled.ts'
import { textSizeM } from '@/styles/theme/fonts.ts'

const MessageLabel = styled.div`
  display: flex;
  gap: 8px;
  margin-bottom: 8px;
`

const MessageLabelText = styled(BodyM)`
  font-weight: 500;
  color: ${({ theme }) => theme.colors.common.black95};
`

const MessageLabelImage = styled.img`
  width: 22px;
  height: 22px;
  background-color: ${({ theme }) => theme.colors.common.accentDark};
  border-radius: 3px;
`

const SMessageContainer = styled(MessageContainer)`
  border: 1px solid ${({ theme }) => theme.colors.common.border};
  background: ${({ theme }) => theme.colors.common.background};
  color: ${({ theme }) => theme.colors.common.black};
  border-radius: 8px;
  ${textSizeM};
`

const Modal = styled(StyledModal)`
  max-height: 100%;
  overflow-y: auto;
  width: 70%;
`

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: flex-start;
  text-align: center;
`

const ModalCaption = styled(BodyS)`
  color: ${({ theme }) => theme.colors.common.black95};
  font-weight: 500;
  margin-bottom: 4px;
  text-align: start;
`

const ModalButton = styled(StyledActionButton)`
  margin-top: 16px;
  align-self: flex-end;
`

const Message = styled(BodyM)`
  width: 100%;
  text-align: start;
  white-space: pre-wrap;
`

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.common.border};
  margin: 16px 0;
`

const EditedMessage = styled(Message)`
  color: ${({ theme }) => theme.colors.common.accentDark};
`

const TagList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
`

const Tag = styled(Checkbox)`
  background-color: ${({ theme }) => theme.colors.common.background};
  color: ${({ theme }) => theme.colors.common.black};
  border: 1px solid ${({ theme }) => theme.colors.common.border};
  border-radius: 4px;
  padding: 2px 8px;
  cursor: pointer;
  &[data-checked] {
    background-color: ${({ theme }) => theme.colors.common.accentDark};
    border-color: ${({ theme }) => theme.colors.common.accentDark};
    color: ${({ theme }) => theme.colors.common.white};
  }
`

const StyledTextArea = styled(TextArea)`
  border: 1px solid ${({ theme }) => theme.colors.common.accentDark};
  background-color: ${({ theme }) => theme.colors.common.white};
  border-radius: 8px;
  ${textSizeM};
  padding: 12px 16px;
  max-width: 80%;
  white-space: pre-wrap;
  text-align: left;
`

export {
  Divider,
  EditedMessage,
  Message,
  MessageLabel,
  MessageLabelImage,
  MessageLabelText,
  Modal,
  ModalButton,
  ModalCaption,
  ModalContent,
  SMessageContainer,
  StyledTextArea,
  Tag,
  TagList,
}
