import { z } from 'zod'

const DCAssistantChatMessageSchema = z.object({
  chatId: z.string().optional(),
  content: z.string(),
  createdAt: z.string().optional(),
  id: z.string().optional(),
  key: z.string().optional().nullable(),
  role: z.literal('user').or(z.literal('assistant')).or(z.string()),
})

export const DCAssistantChatSchema = z.object({
  createdAt: z.string(),
  id: z.string(),
  messages: z.array(DCAssistantChatMessageSchema),
  title: z.string(),
})

export type DCAssistantChat = z.infer<typeof DCAssistantChatSchema>

export type DCAssistantChatMessage = z.infer<
  typeof DCAssistantChatMessageSchema
>

export const DEFECTS = [
  'grammatical',
  'hallucination',
  'spelling',
  'repetition',
  'inappropriate',
  'off_topic',
  'incompleteness',
  'ambiguity',
] as const
