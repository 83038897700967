import { useEffect, useMemo, useState } from 'react'
import { io } from 'socket.io-client'

import { useAuthentication } from '@/contexts/useAuthenticationContext.ts'
import i18n from '@/i18n'
import { CreateHandlerData } from '~common/assistant/client-server.ts'
import {
  ConnectedResponse,
  CreatedResponse,
  DeletedResponse,
  MessageResponse,
} from '~common/assistant/server-client.ts'
import {
  DCAssistantChat as Chat,
  DCAssistantChatMessage as Message,
} from '~common/assistant/types.ts'

export const useDcAssistantChats = () => {
  const { token } = useAuthentication()
  const [chats, setChats] = useState<Chat[]>([])
  const [activeChat, setActiveChat] = useState<string>()
  const [error, setError] = useState<boolean>()

  const socket = useMemo(
    () =>
      io(`${import.meta.env.API_URL}/dc-assistant`, {
        auth: {
          token: `Bearer ${token}`,
        },
        autoConnect: false,
        extraHeaders: {
          'Accept-Language': i18n.language,
        },
        path: '/ws',
        reconnection: true,
        withCredentials: true,
      }),
    [token],
  )

  useEffect(() => {
    if (!token) throw new Error('No token')
    socket.connect()

    socket.on('disconnect', (reason) => {
      if (reason === 'io server disconnect') {
        socket.connect()
      }
    })

    socket.on('connect', () => {
      //console.log('Connected to server')
    })

    socket.on('connected', ({ chats }: ConnectedResponse) => {
      setChats(chats)
    })

    socket.on('created', (chat: CreatedResponse) => {
      console.log('created chat', chat)
      setChats((prev) => {
        return [chat, ...prev]
      })
      setActiveChat(chat.id)
    })

    socket.on('deleted', ({ chatId }: DeletedResponse) => {
      console.log('deleted chat', chatId)
      setChats((prev) => {
        return prev.filter((chat) => chat.id !== chatId)
      })
      setActiveChat(undefined)
    })

    socket.on('message', (message: MessageResponse) => {
      console.log('received event', message)
      setChats((prev) => {
        return prev.map((chat) => {
          if (chat.id === message.chatId) {
            return {
              ...chat,
              messages: [...chat.messages, message],
            }
          }
          return chat
        })
      })
    })

    socket.on('error', () => {
      setError(true)
      setTimeout(() => {
        setError(false)
      }, 5000)
    })

    return () => {
      socket.removeAllListeners()
      socket.disconnect()
    }
  }, [socket, token])

  const assignChatMessage = (message: Message) => {
    setChats((prev) => {
      return prev.map((chat) => {
        if (chat.id === message.chatId) {
          return {
            ...chat,
            messages: [...chat.messages, message],
          }
        }
        return chat
      })
    })
  }
  const sendMessage = (message: Message) => {
    assignChatMessage(message)
    socket.emit('message', {
      chatId: message.chatId,
      content: message.content,
    })
  }

  const createChat = (message: CreateHandlerData) => {
    socket.emit('create', message)
  }

  const deleteChat = (chatId: string) => {
    socket.emit('delete', { chatId })
  }

  return {
    activeChat,
    chats,
    createChat,
    deleteChat,
    error,
    sendMessage,
    setActiveChat,
  }
}
