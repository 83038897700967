import React from 'react'
import { useTranslation } from 'react-i18next'
import { RxArrowRight } from 'react-icons/rx'

import { StyledActionButton } from '@/components/common/Buttons.tsx'
import { ModalBackground } from '@/components/common/Modal.tsx'
import { Header3 } from '@/components/common/Text.tsx'
import { ExerciseTile } from '@/components/templates/Tiles/ExerciseTile.tsx'
import { SidebarType } from '@/contexts/HandTherapyContext.tsx'
import { useHandTherapyContext } from '@/contexts/useHandTherapyContext.ts'
import { Exercise } from '@/types/exercise.ts'

import * as S from './Exercises.styled.ts'

export const ExerciseTilesSection = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'handTherapy',
  })

  const [modalExerciseVideo, setModalExerciseVideo] = React.useState<string>()
  const { actions, currentProgram, sidebar } = useHandTherapyContext()

  if (!currentProgram) {
    return <></>
  }

  const handleDragAndDropOrder = (dragIndex: number, hoverIndex: number) => {
    actions?.reorderExercises(dragIndex, hoverIndex)
    actions?.setIsDirty(true)
  }

  const handleVideoPreviewClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    exercise: Exercise,
  ) => {
    e.stopPropagation()
    e.preventDefault()
    setModalExerciseVideo(exercise.video)
  }
  return (
    <S.Section>
      <S.SectionHeader>
        <Header3>{t('exercises.title')}</Header3>
        <StyledActionButton
          disabled={SidebarType.Exercises === sidebar}
          onClick={() => actions?.updateSidebar(SidebarType.Exercises)}
          type="button"
          variant="Secondary"
        >
          {t('exercises.button')}
          <RxArrowRight height={24} width={24} />
        </StyledActionButton>
      </S.SectionHeader>
      {currentProgram.exercises?.length > 0 && (
        <S.TilesContainer>
          {currentProgram.exercises.map((exercise, i) => (
            <ExerciseTile
              key={exercise.contentfulId}
              exercise={exercise}
              index={i}
              onVideoPreviewClick={(e) => handleVideoPreviewClick(e, exercise)}
              saveDragAndDropOrder={handleDragAndDropOrder}
            />
          ))}
        </S.TilesContainer>
      )}
      {modalExerciseVideo && (
        <ModalBackground closeModal={() => setModalExerciseVideo(undefined)}>
          <S.Modal>
            <S.PreviewVideo src={modalExerciseVideo} controls />
            <S.CloseModalButton
              onClick={() => setModalExerciseVideo(undefined)}
            />
          </S.Modal>
        </ModalBackground>
      )}
    </S.Section>
  )
}
