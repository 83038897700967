import React from 'react'
import { ImAttachment } from 'react-icons/im'
import styled from 'styled-components'

const textareaLineHeight = 24

const StyledTextAreaWrapper = styled.div`
  padding: 8px 12px;
  border-radius: calc(1em + 8px);
  border: 1px solid ${(props) => props.theme.colors.common.border};
  background-color: ${(props) => props.theme.colors.common.background};
  box-sizing: border-box;
  resize: none;
  line-height: ${textareaLineHeight}px;
  overflow: auto;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`

const StyledTextarea = styled.textarea`
  flex: 1;
  cursor: auto;
`

const AttachIcon = styled(ImAttachment)`
  color: ${(props) => props.theme.colors.common.black75};
`

const AttachWrap = styled.div`
  position: relative;
  cursor: pointer;
`

const AttachButton = styled.button`
  margin-left: 16px;
  display: flex;
  height: 100%;
`

interface Props {
  className?: string
  onChange: (s: React.ChangeEvent<HTMLTextAreaElement>) => void
  onChangeFiles?: (e: React.ChangeEvent<HTMLInputElement>) => void
  onKeyDown?: (e: React.KeyboardEvent<HTMLTextAreaElement>) => void
  placeholder?: string
  rows: number
  value: string
  withoutAttachment?: boolean
  disabled?: boolean
}

export const TextArea = ({
  className,
  disabled,
  onChange,
  onChangeFiles,
  onKeyDown,
  placeholder,
  rows,
  value,
  withoutAttachment = false,
}: Props) => {
  const textAreaRef = React.useRef<HTMLTextAreaElement | null>(null)
  const hiddenFileInput = React.createRef<HTMLInputElement>()

  function onAttach() {
    hiddenFileInput.current?.click()
  }

  return (
    <StyledTextAreaWrapper className={className}>
      <StyledTextarea
        ref={textAreaRef}
        onChange={onChange}
        onKeyDown={onKeyDown}
        placeholder={placeholder}
        rows={rows}
        value={value}
        disabled={disabled}
      />
      {!withoutAttachment && (
        <AttachWrap>
          <AttachButton onClick={onAttach}>
            <AttachIcon />
          </AttachButton>
          <input
            ref={hiddenFileInput}
            accept=".jpg,.jpeg,.png,.gif,.pdf,video/*"
            onChange={onChangeFiles}
            style={{ display: 'none' }}
            type="file"
            disabled={disabled}
            multiple
          />
        </AttachWrap>
      )}
    </StyledTextAreaWrapper>
  )
}
