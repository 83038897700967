import {
  ColumnDef,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { useMemo, useState } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { useTranslation } from 'react-i18next'
import { RxInfoCircled } from 'react-icons/rx'

import { HeaderBar } from '@/components/admin/ScreenContainer.styled.ts'
import { CenteredCell, TableContainer } from '@/components/admin/Table.tsx'
import { getTagsData, Tags } from '@/components/admin/Tags.tsx'
import { StyledActionButton } from '@/components/common/Buttons.tsx'
import { TitleSmall } from '@/components/common/Text.tsx'
import {
  Organization,
  useOrganizations,
} from '@/hooks/admin/useOrganizations.ts'
import { useQueues } from '@/hooks/admin/useQueues.ts'
import {
  useDeleteReason,
  useReasons,
  useUpdateReason,
  useUpdateReasons,
} from '@/hooks/admin/useReasons.ts'
import i18n from '@/i18n'
import { ActionButtons } from '@/screens/admin/UserManagement/ActionButtons.tsx'
import { ContentTable } from '@/screens/admin/UserManagement/ContentTable.tsx'
import { DeleteModal } from '@/screens/admin/UserManagement/DeleteModal.tsx'
import { EditModal } from '@/screens/admin/UserManagement/EditModal.tsx'
import { useReasonsFormFields } from '@/screens/admin/UserManagement/Queues/reasons/useReasonsFormFields.ts'
import { denormalizeReason } from '@/screens/admin/UserManagement/Queues/reasons/utils.ts'
import { Queue } from '@/types/queue.ts'
import { ReasonWithOrganizations } from '@/types/reason.ts'

export const ReasonsTable = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'userManagement.reasons',
  })
  const { reasons } = useReasons()
  const { reasonsFormFields } = useReasonsFormFields()
  const { queues } = useQueues()
  const { organizations } = useOrganizations()
  const [reasonToDelete, setReasonToDelete] = useState<string | null>(null)
  const [reasonToEdit, setReasonToEdit] =
    useState<ReasonWithOrganizations | null>(null)
  const [isDragEnabled, setIsDragEnabled] = useState(false)

  const { isError: isErrorUpdating, updateReason } = useUpdateReason({
    onSuccess: () => {
      setReasonToEdit(null)
    },
  })
  const { updateReasons, updateReasonsCache } = useUpdateReasons()

  const { deleteReason, isError: isErrorDeleting } = useDeleteReason({
    onSuccess: () => {
      setReasonToDelete(null)
    },
  })

  const organizationTags = getTagsData(
    organizations?.map((org) => ({
      id: org.code,
      name: org.name,
    })) || [],
  )

  const columns = useMemo<ColumnDef<ReasonWithOrganizations>[]>(
    () => [
      {
        header: '',
        id: 'drag-handle',
      },
      {
        accessorKey: 'isUrgent',
        cell: (info) =>
          info.getValue() ? (
            <CenteredCell>
              <RxInfoCircled color="red" />
            </CenteredCell>
          ) : (
            ''
          ),
        enableColumnFilter: false,
        header: t('columns.urgent'),
      },
      {
        accessorKey: 'title',
        enableColumnFilter: false,
        header: t('columns.title'),
      },
      {
        accessorKey: 'priority',
        enableColumnFilter: false,
        header: t('columns.priority'),
      },
      {
        accessorKey: 'contentfulId',
        enableColumnFilter: false,
        header: t('columns.contentfulId'),
      },
      {
        accessorKey: 'queue',
        cell: (info) => (info.getValue() as Queue).title,
        enableColumnFilter: false,
        header: t('columns.queue'),
      },
      {
        accessorKey: 'organizations',
        cell: (info) => (
          <Tags
            colorMap={organizationTags}
            data={(info.getValue() as Organization[]).map((org) => ({
              id: org.code,
              name: org.name,
            }))}
          />
        ),
        enableColumnFilter: false,
        header: t('columns.organizations'),
      },
      {
        accessorFn: (row) => row,
        cell: ({ getValue }) => {
          const reason = getValue() as ReasonWithOrganizations
          return (
            <ActionButtons
              onDelete={() => setReasonToDelete(reason.id)}
              onEdit={() => setReasonToEdit(reason)}
            />
          )
        },
        enableColumnFilter: false,
        header: t('columns.actions'),
        id: 'actions',
      },
    ],
    [i18n.language],
  )

  const table = useReactTable<ReasonWithOrganizations>({
    columns,
    data: reasons || [],
    getCoreRowModel: getCoreRowModel(),
    getRowId: (row) => row.id,
  })

  return (
    <div>
      <HeaderBar>
        <TitleSmall>{t('title')}</TitleSmall>
        {isDragEnabled ? (
          <StyledActionButton
            onClick={() => {
              if (reasons) {
                updateReasons({
                  reasons,
                })
              }
              setIsDragEnabled(false)
            }}
            size="small"
            variant="Primary"
          >
            {t('actions.saveOrder')}
          </StyledActionButton>
        ) : (
          <StyledActionButton
            onClick={() => {
              setIsDragEnabled(true)
            }}
            size="small"
            variant="Secondary"
          >
            {t('actions.editOrder')}
          </StyledActionButton>
        )}
      </HeaderBar>
      <TableContainer>
        <DndProvider backend={HTML5Backend}>
          <ContentTable
            data={reasons || []}
            isDragEnabled={isDragEnabled}
            table={table}
            updateData={updateReasonsCache}
          />
        </DndProvider>

        {reasonToDelete && (
          <DeleteModal
            caption={t('deleteModal.caption', {
              title: reasons?.find((r) => r.id === reasonToDelete)?.title,
            })}
            closeModal={() => setReasonToDelete(null)}
            errorMessage={t('deleteModal.error')}
            isError={isErrorDeleting}
            onDelete={() => deleteReason({ reasonId: reasonToDelete })}
            title={t('deleteModal.title')}
          />
        )}
        {reasonToEdit && (
          <EditModal
            closeModal={() => setReasonToEdit(null)}
            data={reasonToEdit}
            errorMessage={t('editModal.error')}
            formFields={reasonsFormFields}
            isError={isErrorUpdating}
            onSave={(reasonWithOrg) => {
              const reason = denormalizeReason(reasonWithOrg)
              updateReason({
                reason,
                reasonId: reasonToEdit.id,
              })
            }}
            selectOptions={{
              organizations,
              priority: ['low', 'medium', 'high'],
              queue: queues,
            }}
            title={t('editModal.title')}
          />
        )}
      </TableContainer>
    </div>
  )
}
