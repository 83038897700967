import { useTranslation } from 'react-i18next'

import {
  ConversationAssignedEvent,
  ConversationClosedEvent,
  ConversationEvent,
  ConversationMovedEvent,
  ConversationReasonForEntryChangedEvent,
  ConversationUnassignedEvent,
  InternalConversationEvent,
} from '~common/serverToClientParser.ts'

import * as S from './ChatComponent.styled.ts'
import { ChatMessageAdded } from './ChatMessageAdded.tsx'

function ConversationAssigned({ event }: { event: ConversationAssignedEvent }) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'chat.events',
  })

  return (
    <S.ChatEventContainer data-testid="conversationAssignedEvent">
      <S.EventText>
        {t('conversationAssignedTo', {
          user: event.assignedToName,
        })}
      </S.EventText>
    </S.ChatEventContainer>
  )
}

function ConversationUnassigned({
  event,
}: {
  event: ConversationUnassignedEvent
}) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'chat.events',
  })

  return (
    <S.ChatEventContainer>
      <S.EventText>
        {event.unassignedBySystem
          ? t('conversationUnassignedBySystem')
          : t('conversationUnassignedBy', { user: event.userName })}
      </S.EventText>
    </S.ChatEventContainer>
  )
}

function ConversationMoved({ event }: { event: ConversationMovedEvent }) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'chat',
  })

  return (
    <S.ChatEventContainer>
      <S.EventText>
        {t('events.conversationMovedToQueue', {
          queue: event.queueTitle,
        })}
      </S.EventText>
    </S.ChatEventContainer>
  )
}

function ConversationReasonForEntryChanged({
  event,
}: {
  event: ConversationReasonForEntryChangedEvent
}) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'chat.events',
  })

  return (
    <S.ChatEventContainer>
      <S.EventText>
        {t('conversationReasonForEntryChanged', {
          new: event.newReasonForEntryTitle,
          old: event.oldReasonForEntryTitle,
          user: event.userName,
        })}
      </S.EventText>
    </S.ChatEventContainer>
  )
}

function ConversationClosed({ event }: { event: ConversationClosedEvent }) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'chat.events',
  })

  return (
    <S.ChatEventContainer>
      <S.EventText>
        {t('conversationClosedBy', { user: event.userName })}
      </S.EventText>
    </S.ChatEventContainer>
  )
}

export const ChatComponent = ({
  assignedToId,
  createdById,
  event,
  hideAttachments,
  isFirst,
  isLast,
}: {
  assignedToId?: string
  createdById?: string
  event: ConversationEvent | InternalConversationEvent
  hideAttachments?: boolean
  isFirst: boolean
  isLast: boolean
}) => {
  switch (event.type) {
    case 'ChatMessageAdded':
    case 'InternalChatMessageAdded':
      return (
        <ChatMessageAdded
          assignedToId={assignedToId}
          createdById={createdById}
          event={event}
          hideAttachments={hideAttachments}
          isFirst={isFirst}
          isLast={isLast}
        />
      )
    case 'ConversationAssigned':
      return <ConversationAssigned event={event} />
    case 'ConversationUnassigned':
      return <ConversationUnassigned event={event} />
    case 'ConversationMoved':
      return <ConversationMoved event={event} />
    case 'ConversationReasonForEntryChanged':
      return <ConversationReasonForEntryChanged event={event} />
    case 'ConversationClosed':
      return <ConversationClosed event={event} />
    default:
      return null
  }
}
