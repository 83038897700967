import styled from 'styled-components'

import { BodyM, BodyS } from '@/components/common/Text.tsx'

const EventText = styled(BodyM)`
  color: ${({ theme }) => theme.colors.common.black62};
  background: ${({ theme }) => theme.colors.common.white};
  padding: 16px;
`

interface ChatEventContainerProps {
  align: 'left' | 'right' | 'center'
  isFirst?: boolean
}

const getFlexPosition = (align: 'left' | 'right' | 'center') => {
  if (align === 'left') {
    return 'flex-start'
  }
  if (align === 'right') {
    return 'flex-end'
  }
  return 'center'
}

const MessageEventContainer = styled.div<ChatEventContainerProps>`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: ${(props) =>
    props.isFirst === true ? props.theme.spacing * 2 : props.theme.spacing}px;
  align-items: ${(props) => getFlexPosition(props.align)};
`

const ChatEventContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  :after {
    content: '';
    position: absolute;
    top: calc(50% - 1px);
    left: 0;
    height: 1px;
    z-index: -1;
    background-color: ${({ theme }) => theme.colors.common.border};
    width: 100%;
  }
`

interface IsFirst {
  isFirst?: boolean
}

const MessageContainer = styled.div<IsFirst>`
  padding: 12px 16px;
  max-width: 80%;
  white-space: pre-wrap;
  text-align: left;
`

const MessageSender = styled(BodyS)`
  margin-bottom: 4px;
`

const PatientMessageContainer = styled(MessageContainer)`
  border: 1px solid ${({ theme }) => theme.colors.common.border};
  background: ${({ theme }) => theme.colors.common.white};
  color: ${({ theme }) => theme.colors.common.black};
  border-radius: ${({ isFirst }) => `${isFirst ? '0' : '8'}px 8px 8px 8px;`};
`

const ClinicianMessageContainer = styled(MessageContainer)`
  border: 1px solid ${({ theme }) => theme.colors.common.background};
  background: ${({ theme }) => theme.colors.common.background};
  color: ${({ theme }) => theme.colors.common.black};
  border-radius: ${({ isFirst }) => `8px ${isFirst ? '0' : '8'}px 8px 8px;`};
`

const ImageContainer = styled.div<{ isSender: boolean }>`
  justify-content: ${(props) => (props.isSender ? `flex-end` : `flex-start`)};
  display: flex;
  width: 100%;
  flex-wrap: wrap;
`

const ImageWrapper = styled.div`
  padding: 8px 0 0 8px;
`

const ImageSmall = styled.img`
  width: 256px;
  height: 256px;
  object-fit: cover;
  border-radius: 8px;
  cursor: pointer;
`

const Timestamp = styled.span`
  padding: 4px;
  color: #5a5a5a;
  font-size: 12px;
`

const ImageHidden = styled.div`
  padding: 40px;
  background-color: #eaedf0;
  color: #222222bf;
  border-radius: 8px;
  text-align: center;
`

const StyledActionButtons = styled.div`
  margin-top: 8px;
  display: flex;
  justify-content: space-between;
  gap: 16px;
`

export {
  ChatEventContainer,
  ClinicianMessageContainer,
  EventText,
  ImageContainer,
  ImageHidden,
  ImageSmall,
  ImageWrapper,
  MessageContainer,
  MessageEventContainer,
  MessageSender,
  PatientMessageContainer,
  StyledActionButtons,
  Timestamp,
}
