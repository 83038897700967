import dayjs from 'dayjs'
import lodashUnescape from 'lodash/unescape'
import React from 'react'
import { useTranslation } from 'react-i18next'

import AttachmentModal from '@/components/templates/ChatArea/AttachmentModal.tsx'
import { useAttachment } from '@/hooks/useAttachment.ts'
import { useCurrentUser } from '@/state/hooks/useCurrentUser.ts'
import {
  ChatMessageAddedEvent,
  InternalConversationEvent,
} from '~common/serverToClientParser.ts'

import * as S from './ChatComponent.styled'

function Attachment({ onClick, url }: { onClick: () => void; url: string }) {
  const dataUrl = useAttachment(url)

  return <S.ImageSmall alt="attachment" onClick={onClick} src={dataUrl} />
}

export function ChatMessageAdded({
  assignedToId,
  createdById,
  event,
  hideAttachments,
  isFirst,
  isLast,
}: {
  assignedToId?: string
  createdById?: string
  event: ChatMessageAddedEvent | InternalConversationEvent
  hideAttachments?: boolean
  isFirst: boolean
  isLast: boolean
}) {
  const currentUser = useCurrentUser()
  const { t } = useTranslation('translation', {
    keyPrefix: 'chat',
  })

  let isSender = false
  if ('isClinician' in event) {
    isSender = event?.isClinician
  } else {
    // if current user is not participant of conversation then sender is user who created conversation
    const isUserSender = [assignedToId, createdById].includes(currentUser.id)
    isSender = isUserSender
      ? currentUser.id === event.userId
      : createdById === event.userId
  }

  const align = isSender ? 'right' : 'left'
  const Wrapper = isSender
    ? S.ClinicianMessageContainer
    : S.PatientMessageContainer

  const [selectedUrl, setSelectedUrl] = React.useState<string | null>(null)
  const formattedTimestamp = dayjs(event.timestamp).isBefore(dayjs(), 'day')
    ? dayjs(event.timestamp).format('DD/MM/YYYY HH:mm')
    : dayjs(event.timestamp).format('HH:mm')

  return (
    <S.MessageEventContainer
      align={align}
      data-testid={`messageEvent${event.id}`}
      isFirst={isFirst}
    >
      {event.userName && <S.MessageSender>{event.userName}</S.MessageSender>}
      {event.content && (
        <Wrapper isFirst={isFirst}>{lodashUnescape(event.content)}</Wrapper>
      )}
      <S.ImageContainer isSender={isSender}>
        {event.attachments?.map((a) =>
          hideAttachments ? (
            <S.ImageHidden key={a.name}>{t('hiddenImage')}</S.ImageHidden>
          ) : (
            <S.ImageWrapper key={a.name}>
              <Attachment onClick={() => setSelectedUrl(a.url)} url={a.url} />
            </S.ImageWrapper>
          ),
        )}
      </S.ImageContainer>
      {event.timestamp && isLast && (
        <S.Timestamp>{formattedTimestamp}</S.Timestamp>
      )}
      {selectedUrl && (
        <AttachmentModal
          onClose={() => setSelectedUrl(null)}
          url={selectedUrl}
        />
      )}
    </S.MessageEventContainer>
  )
}
