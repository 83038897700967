import { z } from 'zod'

export enum Language {
  en = 'en-GB',
  nl = 'nl-NL',
}

export const DEFAULT_LANGUAGE = Language.nl

export const supportedLangs = Object.values(Language)

// 'en' and 'nl' are also supported for backwards compatibility
export const LanguageSchema = z.enum([Language.en, Language.nl, 'en', 'nl'])
