import dayjs from 'dayjs'
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import {
  DEFAULT_LANGUAGE,
  Language,
  supportedLangs,
} from '~common/constants.ts'

import en from './locales/en'
import nl from './locales/nl'

const resources = {
  [Language.en]: {
    translation: en,
  },
  [Language.nl]: {
    translation: nl,
  },
}

i18n.on('languageChanged', (lng) => {
  dayjs.locale(lng.toLowerCase())
})

i18n.use(initReactI18next).init({
  debug: true,
  defaultNS: 'translation',
  fallbackLng: DEFAULT_LANGUAGE,
  lng: DEFAULT_LANGUAGE,
  load: 'currentOnly',
  ns: 'translation',
  resources,
  supportedLngs: supportedLangs,
})

export default i18n
