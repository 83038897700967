import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react'
import { ReactNode } from 'react'
import { MdMoreVert } from 'react-icons/md'
import styled from 'styled-components'

const StyledMenuItems = styled(MenuItems)`
  border: 1px solid ${({ theme }) => theme.colors.common.black10};
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.common.white};
  margin-top: 12px;
  width: 200px;
`

const StyledMenuButton = styled(MenuButton)`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.common.white};
  color: ${({ theme }) => theme.colors.common.black75};
  border: none;
  cursor: pointer;
  border-radius: 50%;
  width: 28px;
  height: 28px;

  &:hover {
    background-color: ${({ theme }) => theme.colors.common.black10};
  }
`

const MenuItemButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 16px;
  width: 100%;
  text-align: right;
  color: ${({ theme }) => theme.colors.common.black};
  border: none;
  cursor: pointer;
  border-bottom: 1px solid ${({ theme }) => theme.colors.common.black10};

  &:last-child {
    border-bottom: none;
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.common.black10};
  }
`

type ActionsMenuProps = {
  actions: {
    label: string
    onClick: () => void
    icon?: ReactNode
  }[]
}
export const ActionsMenu = ({ actions }: ActionsMenuProps) => {
  return (
    <Menu>
      <StyledMenuButton>
        <MdMoreVert />
      </StyledMenuButton>
      <StyledMenuItems anchor="bottom end">
        {actions.map((action) => (
          <MenuItem key={action.label}>
            <MenuItemButton onClick={action.onClick}>
              {action.label}
              {action.icon}
            </MenuItemButton>
          </MenuItem>
        ))}
      </StyledMenuItems>
    </Menu>
  )
}
