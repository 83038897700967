import React from 'react'
import { useTranslation } from 'react-i18next'
import { RxChevronRight } from 'react-icons/rx'
import styled from 'styled-components'

import ChatLineSvg from '@/assets/svgs/chat-white-line.svg'
import { BodyM, BodyS } from '@/components/common/Text.tsx'
import { useCurrentUser } from '@/state/hooks/useCurrentUser.ts'
import { Conversation } from '@/types/conversation.ts'
import { Permission } from '~common/auth/permissions.ts'

import { DateSpan } from '../DateSpan.tsx'
import { CardContainer, CardWrapper, IconWrapper } from '../Sidebar.styled.tsx'

const ChatCardContainer = styled(CardContainer)<{ $expired?: boolean }>`
  flex-direction: column;
  width: 100%;
  gap: 4px;

  &[role='button'] {
    cursor: pointer;
  }

  ${({ $expired, theme }) =>
    $expired
      ? `
    opacity: 50%;
    background-color: ${theme.colors.common.black05}
    pointer-events: none;
  `
      : `
    opacity: 100%;
    background-color: transparent;
`}
`

const FlexContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 8px;
  flex-direction: row;
`

const LinkIcon = styled.span`
  flex: none;
  color: ${(props) => props.theme.colors.common.black75};
  align-items: center;
  display: flex;
`

type ChatCardProps = {
  caption?: string | null
  children?: React.ReactNode
  className?: string
  date?: string | null
  expired?: boolean
  name: string
  onClick?: () => void
  withIcon?: boolean
}

export const Card = ({
  caption,
  children,
  className,
  date,
  expired,
  name,
  onClick,
  withIcon,
}: ChatCardProps) => (
  <ChatCardContainer
    $expired={expired}
    className={className}
    onClick={onClick}
    role={onClick ? 'button' : undefined}
  >
    <FlexContainer>
      {withIcon && <IconWrapper src={ChatLineSvg}></IconWrapper>}

      <CardWrapper>
        <FlexContainer>
          <BodyM color="black" fontWeight="bold">
            {name}
          </BodyM>
          {date && <DateSpan date={date} />}
        </FlexContainer>
        <BodyS>{caption}</BodyS>
      </CardWrapper>

      {onClick && (
        <LinkIcon>
          <RxChevronRight color="black75" />
        </LinkIcon>
      )}
    </FlexContainer>

    {children}
  </ChatCardContainer>
)

export const InternalChatCard = styled(Card)`
  border: none;
  width: 100%;
  border-left: 1px solid ${({ theme }) => theme.colors.common.black10};
  margin-left: 38px;
  padding-left: 32px;

  [role='button'] &[role='button']:hover {
    background: none;
  }
`

export const ChatCard = ({
  conversation,
  onClick,
}: {
  conversation: Conversation
  onClick?: (conversation: Conversation, activeInternalChatId?: string) => void
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'patientSidebar.chats',
  })
  const user = useCurrentUser()

  const expired = user.permissions.includes(Permission.PastChats)
    ? false
    : isConversationExpired(conversation)

  const handleClick = (internalConversationId?: string) => {
    if (onClick) {
      onClick(conversation, internalConversationId)
    }
  }
  return (
    <Card
      caption={conversation.assignedTo}
      date={conversation.closedAt}
      expired={expired}
      name={conversation.reasonForEntryTitle}
      onClick={
        conversation.closedAt && !expired ? () => handleClick() : undefined
      }
      withIcon
    >
      {conversation.internalConversations.map((internalConversation) => (
        <InternalChatCard
          key={internalConversation.id}
          caption={`${internalConversation.assignedToName}, ${internalConversation.createdByName}`}
          date={internalConversation.closedAt}
          name={t('internal.title')}
          onClick={
            internalConversation.closedAt && !expired
              ? () => handleClick(internalConversation.id)
              : undefined
          }
        />
      ))}
    </Card>
  )
}

const isConversationExpired = (conversation: Conversation) => {
  if (!conversation.closedAt) {
    return false
  }

  const oneWeekAgo = new Date()
  oneWeekAgo.setDate(oneWeekAgo.getDate() - 7)

  const closedAt = new Date(conversation.closedAt)

  return closedAt < oneWeekAgo
}
