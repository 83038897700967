import Markdown from 'react-markdown'
import styled from 'styled-components'

import { BodyM, BodyS } from '@/components/common/Text.tsx'
import { NAVBAR_HEIGHT } from '@/components/templates/NavigationBar/NavigationBar.styled.ts'

const SIDEBAR_WIDTH = 362

const Container = styled.div`
  display: grid;
  width: 100%;
  height: calc(100vh - ${NAVBAR_HEIGHT}px);
  grid-template-columns: ${SIDEBAR_WIDTH}px 1fr;
  grid-template-rows: ${NAVBAR_HEIGHT}px 1fr;
`

const Header = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 20px;
  align-items: center;
  padding: 16px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.common.border};
`

const ChatKeyInput = styled.input`
  padding: 8px;
  border: 1px solid ${({ theme }) => theme.colors.common.border};
  border-radius: 8px;
  margin-right: 16px;
`

const ChatsSidebar = styled.div`
  padding: 24px;
  display: flex;
  grid-row-start: span 2;
  flex-direction: column;
  border-right: 1px solid ${({ theme }) => theme.colors.common.border};
  overflow-y: auto;
`

const SidebarHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`

const ChatItem = styled.button<{ $isActive: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  background-color: ${({ $isActive, theme }) =>
    $isActive && theme.colors.common.black05};
  border-radius: 8px;
  margin: 4px 0;

  &:hover {
    background-color: ${({ theme }) => theme.colors.common.black10};
  }
`

const ChatInfo = styled.div`
  display: flex;
  flex: 0 1 90%;
  flex-direction: column;
  align-items: flex-start;
`

const ChatTitle = styled(BodyM)`
  color: ${({ theme }) => theme.colors.common.black};
  line-height: 24px;
`

const ChatDate = styled(BodyS)`
  color: ${({ theme }) => theme.colors.common.black75};
  flex: 0 1 10%;
  margin-top: 4px;
  text-align: right;
`

const ChatContent = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: hidden;
`

const MessagesContainer = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: 16px;
`

const Message = styled.div<{ $isUser: boolean }>`
  margin: 5px 0;
  display: flex;
  justify-self: ${({ $isUser }) => ($isUser ? 'flex-end' : 'flex-start')};
  flex-direction: column;
  color: ${({ theme }) => theme.colors.common.black};
  background: ${({ $isUser, theme }) =>
    $isUser ? theme.colors.common.background : theme.colors.common.white};
  border: 1px solid
    ${({ $isUser, theme }) =>
      $isUser ? theme.colors.common.background : theme.colors.common.border};
  padding: 12px 16px;
  border-radius: 8px;
  max-width: 80%;
`

const MessageText = styled(Markdown)<{ $isUser: boolean }>`
  color: ${({ theme }) => theme.colors.common.black};
  white-space: pre-wrap;
`

const MessageCaption = styled(BodyS)`
  color: ${({ theme }) => theme.colors.common.black62};
  align-self: flex-end;
  margin-top: 16px;
`

const InputWrapper = styled.div`
  display: flex;
  border-top: 1px solid ${({ theme }) => theme.colors.common.border};
  padding: 16px;
`

const IconButton = styled.button`
  font-size: inherit;
  background-color: ${({ theme }) => theme.colors.common.white};
  border: none;
  padding-left: 24px;
  cursor: pointer;
  flex-grow: 0;
`

const NewChatButton = styled.button`
  background-color: ${({ theme }) => theme.colors.common.accentDark};
  color: ${({ theme }) => theme.colors.common.white};
  border: none;
  border-radius: 50%;
  cursor: pointer;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 16px;
    height: 16px;
  }

  &:hover {
    opacity: 0.9;
  }
  &:active {
    opacity: 0.7;
  }
  &:disabled {
    opacity: 0.7;
    cursor: default;
  }
`

const DeleteButton = styled.button`
  color: ${({ theme }) => theme.colors.common.black75};
  border: none;
  border-radius: 50%;
  cursor: pointer;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: ${({ theme }) => theme.colors.common.black10};
  }
`

const Error = styled(BodyM)`
  padding: 4px 8px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.common.error};
  color: ${({ theme }) => theme.colors.common.alert};
`

export {
  ChatContent,
  ChatDate,
  ChatInfo,
  ChatItem,
  ChatKeyInput,
  ChatsSidebar,
  ChatTitle,
  Container,
  DeleteButton,
  Error,
  Header,
  IconButton,
  InputWrapper,
  Message,
  MessageCaption,
  MessagesContainer,
  MessageText,
  NewChatButton,
  SidebarHeader,
}
